import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Image, ListGroup, Row } from "react-bootstrap"
import styled from "styled-components"
import { Button } from "./Button"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  RobotoMedium,
  RobotoNormal2,
  MPMedium,
  MPAccent,
} from "../styles/PageStyles.js"

export const ServicesTemplate = ({ services }) => (
  <StyledCol lineBreak>
    <Row>
      {services.map((service, index) => (
        <StyledCol
          lg={4}
          key={index}
          isPadded
          isRelativePosition
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay={service.delay}
          data-aos-offset={service.offset}
        >
          <StyledCol lg={12} xs={11} style={{ padding: 0 }}>
            <Image
              className="service-img"
              src={service.image.publicURL}
              alt={service.title}
              height="350"
              style={{
                objectFit: service.fit,
                background: service.background,
              }}
            />
            <Overlay>
              <ServicesIcon>
                <Image
                  src={service.icon.publicURL}
                  alt={service.title}
                  height="50"
                />
              </ServicesIcon>
              <MPAccent style={{ marginBottom: 10 }}>{service.title}</MPAccent>
              <RobotoNormal2>{service.description}</RobotoNormal2>
            </Overlay>
          </StyledCol>
        </StyledCol>
      ))}
    </Row>
  </StyledCol>
)

const ServicesContent = () => {
  const [reactNativeActive, setReactNativeActive] = useState(false)
  const [reactActive, setReactActive] = useState(false)
  const [angularActive, setAngularActive] = useState(false)
  const [graphqlActive, setGraphqlActive] = useState(false)
  const [microsoftNetActive, setMicrosoftNetActive] = useState(false)
  const [microsoftAzureActive, setMicrosoftAzureActive] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
        frontmatter {
          ourServices {
            services {
              image {
                publicURL
              }
              icon {
                publicURL
              }
              title
              description
              fit
              background
              delay
              offset
            }
          }
          ourWorkingProcess {
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            imageForMobile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          technologyStack {
            description
          }
          consultationPackage {
            title
            packageList {
              text
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          banner {
            title
            description
            image {
              publicURL
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { services } = frontmatter.ourServices
  const { packageList } = frontmatter.consultationPackage
  const workingProcessImg = getImage(frontmatter.ourWorkingProcess.image)
  const workingProcessMobileImg = getImage(
    frontmatter.ourWorkingProcess.imageForMobile
  )
  const consultationPackageImg = getImage(frontmatter.consultationPackage.image)

  return (
    <StyledContainer fluid>
      <StyledRow paddingAlign>
        {/* OUR SERVICES SECTION */}
        <StyledCol lg={12} lineBreak>
          <Div>
            <RobotoMedium>Our Services</RobotoMedium>
          </Div>
        </StyledCol>
        <ServicesTemplate services={services} />
      </StyledRow>

      <StyledRow>
        {/* OUR WORKING PROCESS SECTION */}
        <StyledCol lg={12} lineBreak>
          <Row>
            <StyledCol lg={12} lineBreak>
              <Div>
                <RobotoMedium>Our Working Process</RobotoMedium>
              </Div>
            </StyledCol>

            {/* OUR WORKING PROCESS DESKTOP VIEW */}
            <StyledCol
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="300"
              className="working-process-desktop"
            >
              <GatsbyImage
                image={workingProcessImg}
                alt="Our Working Process"
              />
            </StyledCol>

            {/* OUR WORKING PROCESS MOBILE VIEW */}
            <StyledCol
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="300"
              className="working-process-mobile"
            >
              <GatsbyImage
                image={workingProcessMobileImg}
                alt="Our Working Process"
              />
            </StyledCol>
          </Row>
        </StyledCol>

        {/* TECHNOLOGY STACK SECTION */}
        <StyledCol lg={12} lineBreak>
          <Div>
            <RobotoMedium>Technology Stack</RobotoMedium>
          </Div>
        </StyledCol>
        <StyledCol lg={12} lineBreak>
          <RobotoNormal2>
            {frontmatter.technologyStack.description}
          </RobotoNormal2>
        </StyledCol>
        <Row lg={3} xs={1} style={{ paddingBottom: "10vh" }}>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setReactNativeActive(true)}
            onMouseOut={() => setReactNativeActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={reactNativeActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/react-native-logo.png"
                  alt="React Native"
                />
              </ColoredStack>
              <WhiteStack
                style={reactNativeActive ? { opacity: 0 } : { opacity: 1 }}
              >
                <StaticImage
                  className="tech-stack-white-img"
                  src="./multimedia/react-native-white-logo.png"
                  alt="React Native"
                />
              </WhiteStack>
            </div>
          </StyledCol>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setAngularActive(true)}
            onMouseOut={() => setAngularActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={angularActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/angular-logo.png"
                  alt="Angular"
                />
              </ColoredStack>
              <WhiteStack
                style={angularActive ? { opacity: 0 } : { opacity: 1 }}
              >
                <StaticImage
                  className="tech-stack-white-img"
                  src="./multimedia/angular-white-logo.png"
                  alt="Angular"
                />
              </WhiteStack>
            </div>
          </StyledCol>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setReactActive(true)}
            onMouseOut={() => setReactActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={reactActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/react-logo.png"
                  alt="React"
                />
              </ColoredStack>
              <WhiteStack style={reactActive ? { opacity: 0 } : { opacity: 1 }}>
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/react-white-logo.png"
                  alt="React"
                />
              </WhiteStack>
            </div>
          </StyledCol>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setGraphqlActive(true)}
            onMouseOut={() => setGraphqlActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={graphqlActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/graphql-logo.png"
                  alt="GraphQL"
                />
              </ColoredStack>
              <WhiteStack
                style={graphqlActive ? { opacity: 0 } : { opacity: 1 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/graphql-white-logo.png"
                  alt="GraphQL"
                />
              </WhiteStack>
            </div>
          </StyledCol>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setMicrosoftNetActive(true)}
            onMouseOut={() => setMicrosoftNetActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={microsoftNetActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/microsoft-net-logo.png"
                  alt="Microsoft .NET"
                />
              </ColoredStack>
              <WhiteStack
                style={microsoftNetActive ? { opacity: 0 } : { opacity: 1 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/microsoft-net-white-logo.png"
                  alt="Microsoft .NET"
                />
              </WhiteStack>
            </div>
          </StyledCol>
          <StyledCol
            lg={4}
            md={4}
            xs={12}
            isPadded
            onMouseMove={() => setMicrosoftAzureActive(true)}
            onMouseOut={() => setMicrosoftAzureActive(false)}
          >
            <div style={{ position: "relative" }}>
              <ColoredStack
                style={microsoftAzureActive ? { opacity: 1 } : { opacity: 0 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/microsoft-azure-logo.png"
                  alt="Microsoft Azure"
                />
              </ColoredStack>
              <WhiteStack
                style={microsoftAzureActive ? { opacity: 0 } : { opacity: 1 }}
              >
                <StaticImage
                  className="tech-stack-img"
                  src="./multimedia/microsoft-azure-white-logo.png"
                  alt="Microsoft Azure"
                />
              </WhiteStack>
            </div>
          </StyledCol>
        </Row>
      </StyledRow>
      <StyledRow paddingAlign>
        {/* FREE CONSULTATION SECTION */}
        <StyledCol lg={12} lineBreak>
          <Div>
            <RobotoMedium>Our Consultation Package</RobotoMedium>
          </Div>
        </StyledCol>
        <StyledCol lg={12} md={12} sm={12} isRow reversed lineBreak>
          <StyledCol
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-offset="300"
          >
            <ListGroup>
              <FreeItem>
                <RobotoMedium lineBreak>
                  {frontmatter.consultationPackage.title}
                </RobotoMedium>
              </FreeItem>
              {packageList.map((list, index) => (
                <FreeItem key={index}>
                  <RobotoNormal2>{list.text}</RobotoNormal2>
                </FreeItem>
              ))}
            </ListGroup>
          </StyledCol>
          <StyledCol
            lg={6}
            md={6}
            sm={12}
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-offset="300"
          >
            <GatsbyImage image={consultationPackageImg} alt="FREE" />
          </StyledCol>
        </StyledCol>
      </StyledRow>

      {/* BOTTOM BANNER SECTION */}
      <div data-aos="zoom-out" data-aos-delay="200">
        <Banner>
          <CenteredDiv>
            <Div>
              <MPMedium style={{ color: "#FFB700" }}>
                {frontmatter.banner.title}
              </MPMedium>
            </Div>
            <MPAccent>{frontmatter.banner.description}</MPAccent>
            <StyledButton primary="true" round="true" to="/project-estimation">
              Estimate Project
            </StyledButton>
          </CenteredDiv>
          <StyledImage src={frontmatter.banner.image.publicURL} alt="Banner" />
        </Banner>
      </div>
    </StyledContainer>
  )
}

export default ServicesContent

const StyledContainer = styled(Container)`
  padding: 10vh 0 0 0;
`

const StyledRow = styled(Row)`
  padding: 0 10% 0 10%;

  @media screen and (max-width: 768px) {
    padding: ${({ paddingAlign }) => (paddingAlign ? "0" : "0 10% 0 10%")};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  position: ${({ isRelativePosition }) =>
    isRelativePosition ? "relative" : "static"}
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ isPadded }) => (isPadded ? "15px !important" : undefined)};
  flex-direction: ${({ isRow }) => (isRow ? "row" : "column")};
  margin-bottom: ${({ lineBreak }) => (lineBreak ? "5vh" : "0")};

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
  }

  & .service-img {
    display: block;
    width: 100%;
    border-bottom-right-radius: 30px;
  }

  & .working-process-desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  & .working-process-mobile {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`

const ColoredStack = styled.div`
  transition: 0.5s ease-in-out;
`

const WhiteStack = styled.div`
  position: absolute;
  top: 0;
  transition: 0.5s ease-in-out;
`

const Overlay = styled.div`
  * {
    color: black;
    transform: translateY(0);
    transition: 0.5s ease-in-out;
  }
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffb700;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 30px;
  transition: 0.5s ease-in-out;
  padding: 25px 50px 25px 50px;

  &:hover {
    opacity: 0;

    * {
      transform: translateY(20px);
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 25px;
    justify-content: center;
  }
`

const ServicesIcon = styled.div`
  width: 50px;
  height: 50px;
  margin: 25px;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
`

const FreeItem = styled(ListGroup.Item)`
  background: transparent;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`
// const BusinessItem = styled(ListGroup.Item)`
//   background: transparent;
//   display: flex;
//   justify-content: flex-start;

//   @media screen and (max-width: 768px) {
//     justify-content: center;
//   }
// `

// Bottom Banner Styled Components

const Banner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  background-color: rgba(0, 0, 0, 0.5);
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 50%;
  font-size: 16px;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-top: 5vh;
  background: #ff4700 !important;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    color: black !important;
  }
`

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  z-index: -1;

  @media screen and (max-width: 768px) {
    height: 50vh;
    object-fit: cover;
    object-position: center;
  }
`
