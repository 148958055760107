import React from "react"
import { SEO as Seo } from '../components/SEO.js'
import Layout from "../components/Layout"
import ServicesContent from "../components/ServicesContent"
import ServicesHeader from "../components/ServicesHeader"

export default function Services() {
  return (
    <Layout>
      <Seo 
        title="Blackfort - Services"
        description="We provide IT solutions based on proven tools and technologies, as well as emerging platforms. We take you from creating the concept and design, through to developing and delivering the final product that will delight your customers. Also offered are specialized services such as staffing and managed services."
        url="https://www.blackfort.ph/services"
      />
      <ServicesHeader />
      <ServicesContent />
    </Layout>
  )
}
